import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _39cd3ae4 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4c282725 = () => interopDefault(import('../pages/home/comp_cls.vue' /* webpackChunkName: "pages/home/comp_cls" */))
const _08025e50 = () => interopDefault(import('../pages/home/cost_credit.vue' /* webpackChunkName: "pages/home/cost_credit" */))
const _582e3f05 = () => interopDefault(import('../pages/home/down_files.vue' /* webpackChunkName: "pages/home/down_files" */))
const _0a395bfe = () => interopDefault(import('../pages/home/first_prm.vue' /* webpackChunkName: "pages/home/first_prm" */))
const _f15d7d52 = () => interopDefault(import('../pages/home/home_dash.vue' /* webpackChunkName: "pages/home/home_dash" */))
const _311d03fe = () => interopDefault(import('../pages/home/left_table.vue' /* webpackChunkName: "pages/home/left_table" */))
const _776e87ed = () => interopDefault(import('../pages/home/map_prm.vue' /* webpackChunkName: "pages/home/map_prm" */))
const _7c8ecb31 = () => interopDefault(import('../pages/home/market_price.vue' /* webpackChunkName: "pages/home/market_price" */))
const _16a56a9c = () => interopDefault(import('../pages/home/mom_prm.vue' /* webpackChunkName: "pages/home/mom_prm" */))
const _e5a67ed0 = () => interopDefault(import('../pages/home/near_miss.vue' /* webpackChunkName: "pages/home/near_miss" */))
const _9bab5a64 = () => interopDefault(import('../pages/home/prm_comp.vue' /* webpackChunkName: "pages/home/prm_comp" */))
const _cbe639d4 = () => interopDefault(import('../pages/home/prm_prod.vue' /* webpackChunkName: "pages/home/prm_prod" */))
const _1044c4e6 = () => interopDefault(import('../pages/home/prod_compar.vue' /* webpackChunkName: "pages/home/prod_compar" */))
const _62dba79a = () => interopDefault(import('../pages/home/prod_time.vue' /* webpackChunkName: "pages/home/prod_time" */))
const _49ec9400 = () => interopDefault(import('../pages/home/quot_ot.vue' /* webpackChunkName: "pages/home/quot_ot" */))
const _28798a20 = () => interopDefault(import('../pages/home/quot_prm.vue' /* webpackChunkName: "pages/home/quot_prm" */))
const _582d20e0 = () => interopDefault(import('../pages/home/rnk_ot.vue' /* webpackChunkName: "pages/home/rnk_ot" */))
const _38cd971d = () => interopDefault(import('../pages/home/what_if.vue' /* webpackChunkName: "pages/home/what_if" */))
const _a615222e = () => interopDefault(import('../pages/motor/comp_cls.vue' /* webpackChunkName: "pages/motor/comp_cls" */))
const _8a6eb46e = () => interopDefault(import('../pages/motor/down_files.vue' /* webpackChunkName: "pages/motor/down_files" */))
const _9881fa86 = () => interopDefault(import('../pages/motor/first_prm.vue' /* webpackChunkName: "pages/motor/first_prm" */))
const _4a0be4c5 = () => interopDefault(import('../pages/motor/left_table.vue' /* webpackChunkName: "pages/motor/left_table" */))
const _1fb737a9 = () => interopDefault(import('../pages/motor/map_prm.vue' /* webpackChunkName: "pages/motor/map_prm" */))
const _bbaade16 = () => interopDefault(import('../pages/motor/market_price.vue' /* webpackChunkName: "pages/motor/market_price" */))
const _8223cb50 = () => interopDefault(import('../pages/motor/mom_prm.vue' /* webpackChunkName: "pages/motor/mom_prm" */))
const _06f7454b = () => interopDefault(import('../pages/motor/motor_dash.vue' /* webpackChunkName: "pages/motor/motor_dash" */))
const _46087154 = () => interopDefault(import('../pages/motor/near_miss.vue' /* webpackChunkName: "pages/motor/near_miss" */))
const _12f79a92 = () => interopDefault(import('../pages/motor/prm_comp.vue' /* webpackChunkName: "pages/motor/prm_comp" */))
const _0a4baa4c = () => interopDefault(import('../pages/motor/prm_prod.vue' /* webpackChunkName: "pages/motor/prm_prod" */))
const _2edfe16e = () => interopDefault(import('../pages/motor/prod_compar.vue' /* webpackChunkName: "pages/motor/prod_compar" */))
const _1bb75856 = () => interopDefault(import('../pages/motor/prod_time.vue' /* webpackChunkName: "pages/motor/prod_time" */))
const _1b957888 = () => interopDefault(import('../pages/motor/quot_ot.vue' /* webpackChunkName: "pages/motor/quot_ot" */))
const _ed725c38 = () => interopDefault(import('../pages/motor/quot_prm.vue' /* webpackChunkName: "pages/motor/quot_prm" */))
const _8f1cecb8 = () => interopDefault(import('../pages/motor/rnk_ot.vue' /* webpackChunkName: "pages/motor/rnk_ot" */))
const _3dd3724e = () => interopDefault(import('../pages/motor/what_if.vue' /* webpackChunkName: "pages/motor/what_if" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _39cd3ae4,
    name: "login___ie"
  }, {
    path: "/home/comp_cls",
    component: _4c282725,
    name: "home-comp_cls___ie"
  }, {
    path: "/home/cost_credit",
    component: _08025e50,
    name: "home-cost_credit___ie"
  }, {
    path: "/home/down_files",
    component: _582e3f05,
    name: "home-down_files___ie"
  }, {
    path: "/home/first_prm",
    component: _0a395bfe,
    name: "home-first_prm___ie"
  }, {
    path: "/home/home_dash",
    component: _f15d7d52,
    name: "home-home_dash___ie"
  }, {
    path: "/home/left_table",
    component: _311d03fe,
    name: "home-left_table___ie"
  }, {
    path: "/home/map_prm",
    component: _776e87ed,
    name: "home-map_prm___ie"
  }, {
    path: "/home/market_price",
    component: _7c8ecb31,
    name: "home-market_price___ie"
  }, {
    path: "/home/mom_prm",
    component: _16a56a9c,
    name: "home-mom_prm___ie"
  }, {
    path: "/home/near_miss",
    component: _e5a67ed0,
    name: "home-near_miss___ie"
  }, {
    path: "/home/prm_comp",
    component: _9bab5a64,
    name: "home-prm_comp___ie"
  }, {
    path: "/home/prm_prod",
    component: _cbe639d4,
    name: "home-prm_prod___ie"
  }, {
    path: "/home/prod_compar",
    component: _1044c4e6,
    name: "home-prod_compar___ie"
  }, {
    path: "/home/prod_time",
    component: _62dba79a,
    name: "home-prod_time___ie"
  }, {
    path: "/home/quot_ot",
    component: _49ec9400,
    name: "home-quot_ot___ie"
  }, {
    path: "/home/quot_prm",
    component: _28798a20,
    name: "home-quot_prm___ie"
  }, {
    path: "/home/rnk_ot",
    component: _582d20e0,
    name: "home-rnk_ot___ie"
  }, {
    path: "/home/what_if",
    component: _38cd971d,
    name: "home-what_if___ie"
  }, {
    path: "/motor/comp_cls",
    component: _a615222e,
    name: "motor-comp_cls___ie"
  }, {
    path: "/motor/down_files",
    component: _8a6eb46e,
    name: "motor-down_files___ie"
  }, {
    path: "/motor/first_prm",
    component: _9881fa86,
    name: "motor-first_prm___ie"
  }, {
    path: "/motor/left_table",
    component: _4a0be4c5,
    name: "motor-left_table___ie"
  }, {
    path: "/motor/map_prm",
    component: _1fb737a9,
    name: "motor-map_prm___ie"
  }, {
    path: "/motor/market_price",
    component: _bbaade16,
    name: "motor-market_price___ie"
  }, {
    path: "/motor/mom_prm",
    component: _8223cb50,
    name: "motor-mom_prm___ie"
  }, {
    path: "/motor/motor_dash",
    component: _06f7454b,
    name: "motor-motor_dash___ie"
  }, {
    path: "/motor/near_miss",
    component: _46087154,
    name: "motor-near_miss___ie"
  }, {
    path: "/motor/prm_comp",
    component: _12f79a92,
    name: "motor-prm_comp___ie"
  }, {
    path: "/motor/prm_prod",
    component: _0a4baa4c,
    name: "motor-prm_prod___ie"
  }, {
    path: "/motor/prod_compar",
    component: _2edfe16e,
    name: "motor-prod_compar___ie"
  }, {
    path: "/motor/prod_time",
    component: _1bb75856,
    name: "motor-prod_time___ie"
  }, {
    path: "/motor/quot_ot",
    component: _1b957888,
    name: "motor-quot_ot___ie"
  }, {
    path: "/motor/quot_prm",
    component: _ed725c38,
    name: "motor-quot_prm___ie"
  }, {
    path: "/motor/rnk_ot",
    component: _8f1cecb8,
    name: "motor-rnk_ot___ie"
  }, {
    path: "/motor/what_if",
    component: _3dd3724e,
    name: "motor-what_if___ie"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
