import { render, staticRenderFns } from "./default.vue?vue&type=template&id=1a3cf430&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navbar: require('/var/www/cornmarket/assets/components/gui/navigation/Navbar.vue').default,Logo: require('/var/www/cornmarket/assets/components/gui/brand/Logo.vue').default,Sidebar: require('/var/www/cornmarket/assets/components/gui/navigation/Sidebar.vue').default,User: require('/var/www/cornmarket/assets/components/gui/auth/User.vue').default,Theme: require('/var/www/cornmarket/assets/components/gui/navigation/Theme.vue').default,Logout: require('/var/www/cornmarket/assets/components/gui/auth/Logout.vue').default})
